import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import links from '@utils/externalLinks';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Typography" titleElement="h2" linkText="Read about typography" linkTo="/foundations/typography/" border={true} icon="typography" mdxType="GridListItem">
    Our hierarchy of readable, accessible and visually appealing typography
    styles for each brand.
  </GridListItem>
  <GridListItem title="Logos" titleElement="h2" linkText="Read about logos" linkTo="/foundations/logos/" border={true} icon="logos" mdxType="GridListItem">
    Guidelines for how to use each of the brand logos.
  </GridListItem>
  <GridListItem title="Layout" titleElement="h2" linkText="Read about layout" linkTo="/foundations/layout/" border={true} icon="layout" mdxType="GridListItem">
    Guidelines for padding and spacing across different breakpoints.
  </GridListItem>
  <GridListItem title="States" titleElement="h2" linkText="Read about states" linkTo="/foundations/states/" border={true} mdxType="GridListItem">
    How to communicate when interactive elements are in different states to the
    user.
  </GridListItem>
  <GridListItem title="Colour" titleElement="h2" linkText="Read about colour" linkTo="/foundations/colour/" border={true} icon="colour" mdxType="GridListItem">
    How individual brand palettes and shared global palettes work together to
    make the Luna colour system.
  </GridListItem>
  <GridListItem title="Design tokens" titleElement="h2" linkText="Read about design tokens" linkTo="/foundations/design-tokens/" border={true} mdxType="GridListItem">
    Learn how to use design tokens to build and maintain consistent user
    interfaces.
  </GridListItem>
    </GridList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      